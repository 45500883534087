import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImageHero from '../generic/ImageHero';
import ContentSeparator from '../generic/ContentSeparator';
import ShoppingListButton from './ShoppingListButton';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import StandardCollection6Promos from '../promo/collections/StandardCollection6Promos';
import { promoShape } from '../promo/shapes';
import MetaProperties from '../generic/MetaProperties';

export const Page = ({
  description,
  imageUrl,
  id,
  occasionId,
  occasionName,
  recipes,
  relatedCollections,
  relatedMenus,
  title,
}) => (
  <>
    <MetaProperties title={title} description={description} />
    <div>
      {(imageUrl || description) && (
        <>
          <ImageHero imageUrl={imageUrl} title={title} description={description} />
          <ContentSeparator />
        </>
      )}
      <StandardCollection12Promos
        id={`menu-${id}-recipes`}
        title={imageUrl || description ? 'The menu' : title}
        promos={recipes}
        maxCollectionSize={recipes.length}
        TitleElement={imageUrl || description ? 'h2' : 'h1'}
        containerClassName="menu-page__recipes-collection"
        promoSynopsisClassName="gel-brevier"
      />
      <ContentSeparator />
      <ShoppingListButton
        href={`/food/menus/${id}/shopping-list`}
        text="See shopping list for this menu"
      />
    </div>
    {(relatedMenus.length > 0 || relatedCollections.length) && occasionId && occasionName && (
      <div className="food-body__grey-background">
        {relatedMenus.length > 0 && occasionId && occasionName && (
          <>
            <StandardCollection6Promos
              id={`menu-${occasionId}-recipes`}
              title={`More ${occasionName} menus`}
              promos={relatedMenus}
              maxCollectionSize={relatedMenus.length}
            />
            <ContentSeparator />
          </>
        )}
        {relatedCollections.length > 0 && occasionId && occasionName && (
          <>
            <StandardCollection6Promos
              id={`collection-${occasionId}-recipes`}
              title={`${occasionName} collections`}
              promos={relatedCollections}
              maxCollectionSize={relatedCollections.length}
            />
            <ContentSeparator />
          </>
        )}
      </div>
    )}
  </>
);

Page.defaultProps = {
  imageUrl: undefined,
  occasionId: undefined,
  occasionName: undefined,
  recipes: [],
  relatedCollections: [],
  relatedMenus: [],
};

Page.propTypes = {
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  id: PropTypes.string.isRequired,
  occasionId: PropTypes.string,
  occasionName: PropTypes.string,
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  relatedCollections: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  relatedMenus: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  title: PropTypes.string.isRequired,
};

export default connect(state => ({
  description: state.collectionReducer.description,
  imageUrl: state.collectionReducer.imageUrl,
  id: state.collectionReducer.id,
  occasionId: state.collectionReducer.occasionId,
  occasionName: state.collectionReducer.occasionName,
  recipes: state.collectionReducer.recipes,
  relatedCollections: state.collectionReducer.relatedCollections,
  relatedMenus: state.collectionReducer.relatedMenus,
  title: state.collectionReducer.title,
}))(Page);
