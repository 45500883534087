import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../generic/IconButton';

if (process.env.CLIENT) {
  require('./ShoppingListButton.scss'); // eslint-disable-line global-require
}

const ShoppingListButton = ({ href, text }) => (
  <div className="shopping-list-button__wrap gel-wrap">
    <div className="gel-layout">
      <div className="gel-layout__item gel-1/1 gel-7/12@m gel-1/3@xl gel-1/4@xxl">
        <IconButton
          className="shopping-list-button"
          Element="a"
          href={href}
          iconClassName="shopping-list-button__icon"
          text={text}
        />
      </div>
    </div>
  </div>
);

ShoppingListButton.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ShoppingListButton;
